import { usePage } from '@inertiajs/vue3'
import { computed, onMounted, Ref, toValue } from 'vue'

import axios from '@/lib/axios'

const enrolled = new Set<string>()

export default function useSplitTest<
	K extends keyof App.Data.SplitTestData,
>(splitTest: K): Ref<App.Data.SplitTestData[K]> {
	const page = usePage<{ splitTests: App.Data.SplitTestData }>()

	const variant = computed(() => page.props.splitTests[splitTest])

	onMounted(() => {
		if (!enrolled.has(splitTest)) {
			enrolled.add(splitTest)

			// TODO: Convert this into a debounced call that is batched
			axios.post('/shop/ab-enrollment', {
				test: splitTest,
				variant: toValue(variant),
			})
		}
	})

	return variant
}
