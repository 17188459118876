<script setup lang="ts">
	import { Bars3Icon, ChevronDoubleRightIcon, PhoneIcon, ShoppingCartIcon, UserCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
	import { usePage } from '@inertiajs/vue3'
	import { useIntersectionObserver } from '@vueuse/core'
	import { DialogContent, DialogOverlay, DialogPortal, DialogRoot, DialogTitle, DialogTrigger, VisuallyHidden } from 'radix-vue'
	import { computed, ref } from 'vue'

	import Journey1 from '@/assets/img/device-card/Journey1.png'
	import Xtr from '@/assets/img/device-card/Xtr.png'
	import XtrPro from '@/assets/img/device-card/XtrPro.png'
	import AccountSidebar from '@/components/marketing/header/AccountSidebar.vue'
	import NavigationSidebar from '@/components/marketing/header/NavigationSidebar.vue'
	import TravlFiLogo from '@/components/marketing/Logo.vue'
	import { Container } from '@/components/ui/container'
	import { Link } from '@/components/ui/link'
	import { useUser } from '@/composables/useGlobalData'
	import { useRouterEvent } from '@/composables/useRouterEvent'
	import useSplitTest from '@/composables/useSplitTest'
	import { useWindow } from '@/composables/useWindow'

	const user = useUser()

	const window = useWindow()

	const page = usePage()
	const returnUrl = computed(() => {
		if (window.value === null) {
			return null
		}

		return window.value.btoa(`${window.value.location.origin}${page.url}`)
	})

	const megamenuVisibility = useSplitTest('shopMegamenu')

	const accountLinks = computed<Array<{
		title: string,
		text?: string,
		url: string,
		method?: 'get' | 'post',
	}>>(() => [{
		title: 'Portal',
		text: 'Activate and manage your device and data plans.',
		url: '/portal',
	}, {
		title: 'Equipment',
		text: 'View invoices and track shipments.',
		url: 'https://shop.travlfi.com/account.php',
	}, {
		title: 'Data Billing',
		text: 'Manage payment methods and history.',
		url: `/portal/billing?returnUrl=${returnUrl.value}`,
	}, {
		title: 'Log Out',
		url: '/logout',
		method: 'post',
	}])

	// TODO: The links should likely be injected in some way
	const topShopLinks = computed(() => [{
		image: Journey1,
		label: 'Journey1',
		type: 'Hotspot',
		url: '/p/journey1/700-010',
	}, {
		image: Xtr,
		label: 'JourneyXTR',
		type: 'Router',
		url: '/p/journeyxtr/700-011',
	}, {
		image: XtrPro,
		label: 'XTR Pro',
		type: '5G Router',
		url: '/p/xtr-pro/700-020',
	}])

	const shopLinks = [{
		label: 'Hotspots',
		url: 'https://shop.travlfi.com/hotspots',
	}, {
		label: 'Routers',
		url: 'https://shop.travlfi.com/routers',
	}, {
		label: 'Accessories',
		url: 'https://shop.travlfi.com/accessories',
	}, {
		label: 'Range Extenders',
		url: 'https://shop.travlfi.com/range-extenders',
	}]

	const additionalShopLinks = [{
		label: 'Cell Boosters',
		url: 'https://shop.travlfi.com/cell-boosters',
	}, {
		label: 'Smart RV Devices',
		url: 'https://shop.travlfi.com/smart-rv-devices',
	}, {
		label: 'Mounting',
		url: 'https://shop.travlfi.com/accessories/mounts/',
	}]

	const navigationLinks = computed(() => [{
		label: 'Shop',
		...(megamenuVisibility.value === 'hidden' ? {
			sublinks: [
				...topShopLinks.value,
				...shopLinks,
				{ label: 'Shop All', url: 'https://shop.travlfi.com/' },
			],
		} : {
			url: 'https://shop.travlfi.com',
		}),
	}, {
		label: 'Activate Device',
		url: '/portal/activate',
	}, {
		label: 'Data Plans',
		url: '/plans',
	}, {
		label: 'Learn More',
		url: '/learn-more',
	}, {
		label: 'Support',
		sublinks: [
			{ label: 'Contact Us', url: '/contact' },
			{ label: 'About Us', url: '/about' },
			{ label: 'Activate Now', url: '/portal/activate' },
			{ label: 'Shipping Policy', url: 'https://shop.travlfi.com/shipping-returns/' },
			{ label: 'Return Policy', url: 'https://shop.travlfi.com/shipping-returns/' },
		],
	}, {
		label: 'Resources',
		sublinks: [
			{ label: 'Blog', url: '/blog' },
			{ label: 'FAQs', url: '/faqs' },
			{ label: 'Where To Buy', url: '/where-to-buy' },
			{ label: 'Affiliate Program', url: '/affiliate-program' },
			{ label: 'Military Program', url: '/military-discount' },
		],
	}])

	const miniBanner = ref(true)

	const shopOpen = ref(false)

	const shopButton = ref<HTMLDivElement | null>(null)
	useIntersectionObserver(shopButton, ([{ isIntersecting }]) => {
		if (!isIntersecting) {
			shopOpen.value = false
		}
	})

	useRouterEvent('finish', () => {
		shopOpen.value = false
	})
</script>

<template>
	<header class="sticky top-0 z-40 bg-white" :class="{'pointer-events-auto': shopOpen}">
		<DialogRoot v-model:open="shopOpen">
			<DialogPortal>
				<DialogOverlay class="fixed inset-0 z-30 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 max-md:hidden" />
			</DialogPortal>

			<DialogContent :aria-describedby="undefined" class="absolute inset-x-0 top-full z-40 transition ease-in-out [animation-timing-function:theme(transitionTimingFunction.in-out)] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top data-[state=closed]:[animation-duration:100ms] data-[state=open]:[animation-duration:200ms] max-md:hidden">
				<nav class="flex w-full items-start justify-center transition">
					<VisuallyHidden>
						<DialogTitle>Shop</DialogTitle>
					</VisuallyHidden>

					<div class="w-full bg-gray-150 py-9">
						<Container class="relative mx-auto flex w-full justify-center gap-x-6 lg:gap-x-12 2xl:gap-x-28">
							<div class="">
								<p class="font-bold text-gray-600 max-lg:hidden">Wi-Fi Devices</p>

								<div class="grid grid-cols-3 gap-x-4 lg:mt-6 lg:gap-x-8 2xl:gap-x-16">
									<Link v-for="link in topShopLinks" :key="link.label" :href="link.url" class="group mx-auto w-full xl:mx-0">
										<div class="flex w-full items-center justify-center rounded-xl bg-white px-4 py-2 xl:px-8 xl:py-8">
											<img :src="link.image" class="mx-auto h-20 object-contain object-center lg:h-24">
										</div>
										<p class="mt-3 text-center font-semibold group-hover:underline lg:text-lg">
											<span>{{ link.label }}</span> <span class="block xl:inline">{{ link.type }}</span>
										</p>
									</Link>
								</div>
							</div>

							<div>
								<p class="font-bold text-gray-600 max-lg:hidden">Categories</p>

								<div class="flex gap-x-8 lg:mt-6">
									<div class="flex shrink-0 flex-col gap-y-3">
										<Link v-for="link in shopLinks" :key="link.label" class="font-semibold hover:underline lg:text-lg" :href="link.url">{{ link.label }}</Link>
										<Link class="font-semibold hover:underline lg:text-lg xl:hidden" href="https://shop.travlfi.com/">
											<span class="flex items-center gap-x-2">
												<span>Shop All</span>
												<ChevronDoubleRightIcon class="size-5" />
											</span>
										</Link>
									</div>
									<div class="flex shrink-0 flex-col gap-y-3 max-xl:hidden">
										<Link v-for="link in additionalShopLinks" :key="link.label" class="font-semibold hover:underline lg:text-lg" :href="link.url">{{ link.label }}</Link>
										<Link class="font-semibold hover:underline lg:text-lg" href="https://shop.travlfi.com/">
											<span class="flex items-center gap-x-2">
												<span>Shop All</span>
												<ChevronDoubleRightIcon class="size-5" />
											</span>
										</Link>
									</div>
								</div>
							</div>
						</Container>
					</div>
				</nav>
			</DialogContent>

			<div v-if="miniBanner" class="relative z-40 flex w-full items-center gap-x-2 bg-purple py-0.5 text-white md:py-1">
				<Container class="flex items-center justify-between">
					<button class="invisible max-sm:hidden" @click="miniBanner = false">
						<XMarkIcon class="size-6" />
					</button>

					<Link class="mx-auto text-pretty text-center lg:text-lg" href="https://shop.travlfi.com/">Save Today With Free Shipping</Link>

					<button class="max-sm:hidden" @click="miniBanner = false">
						<XMarkIcon class="size-6" />
					</button>
				</Container>
			</div>

			<div class="relative z-40 bg-white shadow-md">
				<Container class="flex items-center justify-between py-3 text-gray-700 sm:py-4">
					<div class="flex items-center gap-x-4 font-medium lg:gap-x-6 xl:gap-x-8">
						<NavigationSidebar class="md:hidden" :links="navigationLinks">
							<Bars3Icon class="size-6" />
						</NavigationSidebar>

						<Link href="/" class="no-underline">
							<TravlFiLogo class="h-6 lg:h-8 xl:h-10" />
						</Link>

						<div ref="shopButton" class="max-md:hidden">
							<template v-if="megamenuVisibility === 'visible'">
								<button v-if="shopOpen" class="underline xl:text-xl/9">Shop</button>
								<DialogTrigger v-else class="hover:underline xl:text-xl/9">Shop</DialogTrigger>
							</template>

							<Link v-else class="hover:underline xl:text-xl/9" href="https://shop.travlfi.com/">Shop</Link>
						</div>

						<Link href="/plans" class="hover:underline max-md:hidden xl:text-xl/9">Data</Link>
						<Link href="/learn-more" class="hover:underline max-md:hidden xl:text-xl/9">Learn More</Link>
						<Link href="/contact" class="hover:underline max-md:hidden xl:text-xl/9">Support</Link>
						<Link href="/activate" class="hover:underline max-md:hidden xl:text-xl/9">Activate</Link>
					</div>

					<div class="flex items-center justify-end gap-x-4 font-medium lg:gap-x-6 xl:gap-x-8">
						<Link class="flex items-center justify-center gap-x-4 md:gap-x-3" href="tel:18009606934">
							<PhoneIcon class="size-6 lg:hidden" />
							<span class="max-lg:hidden xl:text-xl/9">1-800-960-6934</span>
						</Link>

						<AccountSidebar v-if="user" class="flex items-center justify-center gap-x-3" :accountLinks="accountLinks">
							<UserCircleIcon class="size-6 xl:size-8" />
							<span class="max-lg:hidden xl:text-xl/9">My Account</span>
						</AccountSidebar>
						<Link v-else class="flex items-center justify-center gap-x-3" href="/portal">
							<UserCircleIcon class="size-6 xl:size-8" />
							<span class="max-lg:hidden xl:text-xl/9">Sign In</span>
						</Link>

						<Link href="https://shop.travlfi.com/cart.php">
							<ShoppingCartIcon class="size-6 xl:size-8" />
						</Link>
					</div>
				</Container>
			</div>
		</DialogRoot>
	</header>
</template>
